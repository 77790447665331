<template>
  <section>
    <form ref="excel-form" class="modal-card" style="width: 550px">
      <section class="modal-card-body">
        <div v-if="!uploadResponse">
          <b-field v-if="dropFiles == null">
            <b-upload
              v-model="dropFiles"
              drag-drop
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            >
              <section class="section">
                <div class="content has-text-centered">
                  <p>
                    <b-icon icon="upload" size="is-large" />
                  </p>
                  <p>{{ $t('uploadText') }}</p>
                </div>
              </section>
            </b-upload>
          </b-field>
          <div v-if="dropFiles !== null" class="tags">
            <span class="tag is-primary">
              {{ dropFiles.name }}
            </span>
          </div>
          <b-notification
            v-if="!uploadingFile"
            type="is-info"
            aria-close-label="Close notification"
          >
            {{ $t('excelExampleFile') }}
            <a href="/files/bfy_go_example_import.xlsx" download>{{ $t('click') }}</a>
          </b-notification>

          <b-notification
            v-if="uploadingFile"
            :closable="false"
            type="is-warning"
            role="alert"
            style="z-index: 1; margin-top: 110px;"
          >
            Dosya Yükleniyor Lütfen Bekleyiniz.
          </b-notification>
          <b-loading :active.sync="uploadingFile" :can-cancel="false" />
        </div>
        <div v-if="uploadResponse">
          <b-notification
            v-if="uploadResponse.success"
            :closable="false"
            type="is-success"
            role="alert"
          >
            <div class="has-text-centered">
              <span class="lg big-font">{{ uploadResponse.count }}</span>
              <p>Üye başarıyla oluşturuldu.</p>
            </div>
          </b-notification>
          <div v-if="!uploadResponse.success">
            <b-notification :closable="false" type="is-warning" role="alert">
              Aktarım sırasında hatalar oluştu. Lütfen kontrol edip tekrar yüklemeyi deneyin.
            </b-notification>
            <b-table
              v-if="!uploadResponse.success"
              :data="uploadResponse.errors"
              :bordered="true"
              :striped="true"
              :narrowed="true"
              :hoverable="true"
            >
              <template slot-scope="props">
                <b-table-column field="id" label="Satir" width="40" numeric>
                  <div class="has-text-centered has-text-weight-bold">
                    {{ props.row[0] }}
                  </div>
                </b-table-column>
                <b-table-column field="hata" label="Hata">
                  <p
                    v-for="(error, key) in props.row[1]"
                    :key="key"
                    :label="key"
                  >
                    <!-- eslint-disable-next-line -->
                    <span v-html="error" />
                  </p>
                </b-table-column>
              </template>
            </b-table>
            <b-button type="is-purple" icon-left="refresh" class="is-fullwidth mt-10" @click="reset">
              Tekrar yükle
            </b-button>
          </div>
        </div>
      </section>
    </form>
  </section>
</template>

<script>
export default {
  name: 'ExcelImportModal',
  data () {
    return {
      dropFiles: null,
      uploadingFile: false,
      uploadResponse: null,
    }
  },
  watch: {
    dropFiles (n) {
      if (n) {
        this.prepareUpload()
      }
    }
  },
  methods: {
    prepareUpload () {
      this.uploadingFile = true
      const formData = new FormData();
      formData.append('file', this.dropFiles, this.dropFiles.name);
      this.startUpload(formData)
    },
    startUpload (formData) {
      this.$http.post('users/import', formData)
        .then(response => {
          this.uploadingFile = false
          this.uploadResponse = response.data
        })
        .catch(() => {
          this.uploadResponse = null
          this.dropFiles = null
          this.uploadingFile = false

          this.$buefy.toast.open({
            message: this.$t('error'),
            type: 'is-danger',
            duration: 10000
          });
        })
    },
    reset () {
      this.uploadResponse = null
      this.dropFiles = null
      this.uploadingFile = false
    }
  }
}
</script>
